*, h1, h2, p, span, a {
  font-size: 16px;
  padding: 0; margin: 0;
}

::selection {
  background-color: #5a5a5a;
  color: #fff;
}

p {
  font-size: 16px;
  margin-bottom: 3.5em;
  line-height: 1.668em;
}

div.white {
  a:link, a:visited { color: #616161; text-decoration: none; }
  a:hover, a:active { color: #4F4F4F; }
}

div.black {
  background-color: #000000;
  a:link, a:visited { color: #616161; text-decoration: none; }
  a:hover, a:active { color: #949494; }
}

header {
  margin-top: 13.313em;
  margin-bottom: 2.750em;
  svg {
    height: $svg-logo-size;
    width: $svg-logo-size;
    margin-left: -$svg-logo-size;
    display: inline-block;
    vertical-align: middle
  }
  h1 {
    span {
      font-size: inherit;
    }

    font-weight: 200;
    font-size: 46px;
    text-transform: lowercase;
    color: #616161;
    padding: 0.2em 0.5em;
    width: calc(100% - 2em);
    display: inline-block;
    vertical-align: middle;
  }
}

section {
  &:first-of-type {
    margin-bottom: 4.375em;
  }
  &:last-child {
    margin-bottom: 8.375em;
  }
  a.btn {
    width: 100%;
    margin-bottom: 4em;
  }
  h2 {
    color: #CACACA;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 1.5em;
  }
  p.currently-working {
    font-weight: bold;
    font-size: 18px;
    color: #616161;
    margin-bottom: 3.125em;

    .availability {
      white-space: nowrap;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 3px;
      font-weight: lighter;
      &.available {
        border-color: #006600;
        color: #006600;
      }
      &.partial {
        border-color: #CC9900;
        color: #CC9900;
      }
      &.not {
        border-color: #CC3300;
        color: #CC3300;
      }
    }

    a:link, a:visited {
      color: #919191;
    }
    a:hover, a:active {
      text-decoration: none;
    }
  }
  div.vita {
    margin-bottom: 3.125em;

    div {
      display: table;
      p {
        display:inline-block;
        margin-bottom: 10px;
        .glyphicon {
          font-size: 1.0em;
          color: #ccc;
          margin-right: 0.2em;
        }
      }

      .vita-year {
        width: 70px;
        display: table-cell;
        text-align: center;
        color: #999;
        font-size: 0.9em;


        border-radius: 1px;
        vertical-align: top;
        padding-bottom: 10px;
        .year-block {
          font-size: 12px;
          padding: 0.365em 0.875em;
          background-color: #efefef;
        }
      }

      .vita-desc {
        display: table-cell;
        font-size: 0.9em;
        padding-top: 5px;
        padding-left: 1em;
        padding-bottom: 10px;
        vertical-align: top;
      }
    }
  }
  p.info-block {
    color: #999;
    font-style: italic;
    text-align: center;
  }
  hr.half-split {
    margin-top: -20px;
    margin-bottom: 20px;
    border-top: 1px solid #eee;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  span.skill {
    color: #FFFFFF;
    background-color: #CACACA;
    padding: 0.365em 0.875em;
    margin-bottom: 0.375em;
    border-radius: 1px;
    font-size: 12px;
    white-space: nowrap;
    &:hover {
      background-color: #AEAEAE;
    }
  }

  a.btn {
    background-color: #4F4F4F;
    display: inline-block;
    color: #fff;
    border-radius: 0;
    padding: 12px 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;

    &.btn-smooth {
      background-color: #bbb;
    }

    &:hover {
      background-color: $color-main-accent;
      color: #fff;
    }
  }

  &.loaded {
    opacity: 1;
  }
}

footer {
  padding-top: 4.063em;
  padding-bottom: 2.063em;

  .picture {
    img {
      width: 80%;
      border-radius: 50%;
      margin-bottom: 3em;
    }
  }

  .contact, .imprint {
    color: #ffffff;
    span {
      color: #616161;
      &:after {
        content: "";
        display: block;
      }
    }

    label {
      min-width: 3.4em;
    }

    .socicon {
      margin: 2em .2em 0em .2em;
      display: inline-block;
      font-size: 18px;
      &:hover {
        color: $color-main-accent;
      }
    }
  }

  &.loaded {
    opacity: 1;
  }
}


@media only screen and (max-width:1199px){
  header {
    svg {
      margin-left: 0;
      display: block;
      margin-left: ($svg-logo-size/2);
      margin-top: -($svg-logo-size*1.5);
      margin-bottom: $svg-logo-size;
    }
    h1 {
      display: block;
      padding-left: 0.66em;
      width: 100%;
    }
  }
}

@media only screen and (max-width:899px){
  footer {
    .contact {
      label {
        margin: 0;
        display: block;
        clear: both;
      }
      span {
        display: block;
        min-width: 200px;
        margin-bottom: .2em;

        &:after {
          content: "";
          display: none;
        }
      }
    }
  }
}

/** Mobile **/
@media only screen and (max-width:625px){
  .grid-container {
    .grid-line {
      .col-third-width {
        display: block;
        width: 100%;
      }
      .col-half-width {
        clear: both;
        width: 100%;
      }
    }
  }

  header {
    h1 {
      span {
        font-size: inherit;
        font-family: "Times New Roman";
        font-style: italic;
      }
    }
  }

  footer {
    .col-third-width {
      text-align: center;

      .picture {
        img {
          width: 50%;
        }
      }

      .imprint {
        .socicon {
          margin: 2em .2em 0em .2em;
          display: inline-block;
          font-size: 18px;
          &:hover {
            color: #2CA0BE;
          }
        }
      }

      &:last-child {
        margin-top: 3em;
        .imprint {
          padding-bottom: 5em;
        }
      }
    }
  }
}