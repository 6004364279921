$cell-padding: 0 2em;

@mixin clearfix() {
  content: "";
  clear: both;
  display: block;
}

.grid-container {
  max-width: 1024px;
  margin-left:auto;
  margin-right:auto;

  .grid-line {
    /** width 100% **/
    .col-full-width {
      width: 100%;
      padding: $cell-padding;
    }

    /** width 50% **/
    .col-half-width {
      width: 50%;
      float: left;
      padding: $cell-padding;
    }

    /** width 33.33% **/
    .col-third-width {
      width: 33.33%;
      display: inline-block;
      vertical-align: middle;
      padding: $cell-padding;
    }

    &:after { @include clearfix(); }
  }
}
