div#chat {
  width: 60%;

  .chat-wrap {
    margin-top: 5em;
  }

  .chat-answer-select {
    clear: both;
    text-align: center;
    margin-right: .5em;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;

    a {
      display: inline-block;
      border-radius: 0px;
      background-color: #fff;
      padding: .5em;
      margin-top: 1em;
      margin-bottom: 1em;
      line-height: 1.4em;
      background-color: #4F4F4F;
      color: #fff;
      margin-right: 4px;
      text-decoration: none;

      input[type=text] {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #4F4F4F;
      }

      .em {
        height: 19px;
      }

      &:hover {
        background-color: $color-main-accent;
        text-decoration: none;
      }
    }
  }
}

div.chat-bubble {
  display: block;
  width: 100%;
  clear: both;

  div.chat-bubble-msg {
    display: block;
    border-radius: 0px;
    padding: .5em;
    min-width: 5%;
    margin-top: 1.4em;
    line-height: 1.4em;
    display: inline-block;

    &.chat-bubble-msg-visitor {
      background-color: #4F4F4F;
      color: #fff;
      float:right;
    }

    &.chat-bubble-msg-me {
      background-color: $color-third-accent;
    }
  }
}

@media only screen and (max-width:625px) {
  div#chat {
    width: 100%;
    .chat-answer-select {
      text-align: right;
      display:block;
      margin: 0;
      a {
        display:block;
        text-align: center;
        margin-bottom: .2em;
        margin-top: .2em;
      }
    }
  }
}